.verify-email-container {
    display: flex;
    justify-content: center;
    height: calc(100dvh - 72px);
}

.verify-email {
    width: 360px;
    margin: auto;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.resend-verify-email {
    display: flex;
    gap: 8px;
    justify-items: center;
    align-items: center;
}

.resend-verify-email p {
    margin: 0px;
    padding: 0px;
    flex-shrink: 0;
}

.verify-email-header {
    padding: 16px;
    height: 72px;
    border-bottom: 1px solid rgb(233, 222, 222);
}

.verify-email-title {
    font-weight: 500;
    font-size: medium;
}