.search-home-btn {
    display: flex;
    justify-content: center;
}

.search-box {
    border-radius: 16px;
    padding: 16px 16px 0px 16px;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.search-box-button {
    display: flex !important;
    gap: 4px !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.search-box-button button {
    height: 47.13px !important;
}

.x {
    height: 66px !important;
}


/* .range-separator {
    justify-content: center;
    align-items: center;
    display: flex;
}

.range-separator::after {
    content: '-';
} */

.custom-placeholder .ant-select-selection-placeholder {
    color: var(--primary) !important;
}

@media screen and (max-width: 992px) {
    .search-box-button {
        display: flex;
        gap: 4px;
    }

    .hide {
        display: none;
    }
}