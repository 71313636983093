.verification-banner {
    background: linear-gradient(43.41deg, #f3dcb5 0%, #f3dcb5 69.16%);
    border-radius: 4px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
}

.verification-banner__content {
    display: flex;
    flex-direction: column;
}

.verification-banner__title {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary);
}

.verification-banner__description {
    font-size: 14px;
    margin: 0px;
    color: var(--primary-grey);
}