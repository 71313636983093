.home-container {
  height: calc(100dvh - 181px);
  max-width: 1440px;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: auto;
}

.home-header-text {
  margin-bottom: 16px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 100px;
}

.home-content {
  width: 80%;
}

.search-container {
  padding: 15px 0px;
  max-width: 1440px;
  margin: auto;
  place-self: center;
}

@media screen and (max-width: 991px) {
  .home-content {
    width: 95%;
  }
}
