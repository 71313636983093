.carousel-container {
    width: 300px;
    height: 200px;
    margin: auto;
}

.carousel-wrapper {
    position: relative;
    width: 300px;
    margin: auto;
}

.carousel-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
}

.carousel-image {
    object-fit: cover;
    width: 100%;
    height: 200px;
    object-position: center;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: transparent;
    border: none;
    font-size: 15px;
    width: 20px !important;
    background: #fff;
}

.left-arrow {
    left: 5px;
}

.right-arrow {
    right: 5px;
}

.time-posted {
    text-align: end;
}

.ApartmentCardContainer {
    display: flex;
    padding: 8px;
    border: 1px solid #d0d5dd;
    border-radius: 16px;
    margin-bottom: 27px;
    cursor: pointer;
    text-decoration: none;
}

.ApartmentCardContainer p {
    color: var(--primary-dark);
    margin: 0px;
    padding: 0px;
}

.ApartmentCardContainer .cardimg {
    width: 194px;
    height: 225px;
    margin-right: 3px;
    border-radius: 16px;
    overflow: hidden;
    object-fit: cover;
}

.ApartmentCardContainer .address {
    display: flex;
    gap: 9px;
    margin-top: 9px;
}

.ApartmentCardContainer .address .icon {
    margin-top: 2px;
}

.ApartmentCardContainer .carddetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
    border-radius: 16px;
}

.ApartmentCardContainer .carddetails .readmore {
    color: var(--primary);
    font-weight: 500;
}

.ApartmentCardContainer .carddetails .cardfooter {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.ApartmentCardContainer .carddetails .cardfooter .properties {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ApartmentCardContainer .carddetails .cardfooter .properties .sub-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 5px;
}

.ApartmentCardContainer .carddetails .cardfooter .cardaction {
    display: flex;
    color: red;
    gap: 10px;
    align-items: center;
}


.ApartmentCardContainer .carddetails .cardfooter .cardaction .sub-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px;
}


@media (max-width:480px) {
    .ApartmentCardContainer {
        width: 100%;
        padding: 0px;
        display: block;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin-bottom: 27px;

    }

    .ApartmentCardContainer .cardimg {
        width: 100%;
        height: 225px;
        border-radius: 0px;
    }

    .ApartmentCardContainer .cardimg img {
        width: 100%;
    }

    .ApartmentCardContainer .carddetails {
        width: 100%;
        padding: 0px 20px;
        border-radius: 16px;
        margin-top: 19px;

    }

    .carousel-container {
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .carousel-wrapper {
        position: relative;
        width: 400px;
        margin: auto;
    }

    .carousel-slide {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 0px;
    }

    .time-posted {
        display: none;
    }

    .ApartmentCardContainer .carddetails .cardfooter {
        margin-top: 27px;
        margin-bottom: 17px;
    }

    .ApartmentCardContainer .carddetails {
        padding: 0px 10px;
    }
}