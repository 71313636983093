.related-property-container {
    margin-top: 4px;
    text-align: start;
}

.related-property-list {
    display: flex;
    overflow-x: scroll;
    align-items: stretch;
    gap: 16px;
    padding: 4px;
    scrollbar-width: none;

}

.related-property-card {
    flex-shrink: 0;
    width: 270px;

}

.property-image-container {
    height: 200px;
    overflow: hidden;
}


.property-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}