.poster-title {
    display: flex;
    justify-items: center;
    gap: 16px;
}

.poster-title p {
    margin: 0px !important;
}

.poster-title .name {
    font-size: 16px;
    font-weight: 600;
}

.description {
    margin: 16px 0px;
}